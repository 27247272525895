.tile {
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    color: black;
    margin: 0.3rem;
    border-radius: 10px;
    font-size: 2em;
    line-height: 1.6em;
    transition: 100ms;
}

.tile.free {
    background-color: white;
}

.tile.taken {
    background-color: wheat;
}

.tile.possible {
    background-color: #bbb;
}

.tile.done {
    background-color: black;
    color: wheat;
}