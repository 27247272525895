.button {
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background-color: wheat;
    color: #242424;
    border-radius: 50%;
    font-size: 1.7em;
    cursor: pointer;
    transition: 200ms;
}

.button i {
    margin: 0 auto;
}

.button:hover {
    background-color: rgb(187, 170, 139);
}

.button:active {
    background-color: rgb(141, 127, 103);
}