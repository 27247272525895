.menu {
    width: fit-content;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.menu a {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 3em;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 3px 10px #00000033;
    transition: 500ms;
}

/*
.menu a:last-child {
    margin-left: 2rem;
}
*/

.menu a:hover {
    background-color: wheat;
}

.menu a:active {
    background-color: rgb(190, 170, 131);
}

.gmc {
    backdrop-filter: blur(5px) saturate(160%);
    -webkit-backdrop-filter: blur(5px) saturate(160%);
    background-color: rgba(90, 90, 90, 0.72);
    border: 1px solid rgba(255, 255, 255, 0.125);
}

@media only screen and (max-device-width: 812px) {
    .menu {
        width: 250px;
    }
    .menu a {
        margin: 0;
    }
    /*
    .menu a:last-child {
        margin: 2rem 0 0 0;
    }
    */
}