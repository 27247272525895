#grid {
    width: fit-content;
    margin: 0 auto;
    padding: 0.3em;
    border-radius: 20px;
}

.buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 3em auto;
}

#bottom-txt {
    color: white;
    text-align: center;
    font-size: 3em;
    margin-top: 1.5em;
}