@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
body {
  margin: 0;
  background-color: #242424;
  font-family: 'Questrial', sans-serif;
}
.menu {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.menu a {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 3em;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 3px 10px #00000033;
    transition: 500ms;
}

/*
.menu a:last-child {
    margin-left: 2rem;
}
*/

.menu a:hover {
    background-color: wheat;
}

.menu a:active {
    background-color: rgb(190, 170, 131);
}

.gmc {
    backdrop-filter: blur(5px) saturate(160%);
    -webkit-backdrop-filter: blur(5px) saturate(160%);
    background-color: rgba(90, 90, 90, 0.72);
    border: 1px solid rgba(255, 255, 255, 0.125);
}

@media only screen and (max-device-width: 812px) {
    .menu {
        width: 250px;
    }
    .menu a {
        margin: 0;
    }
    /*
    .menu a:last-child {
        margin: 2rem 0 0 0;
    }
    */
}
.title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 1em;
}

.title h1 {
    font-size: 5em;
    line-height: 0em;
    color: wheat;
}
.tile {
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    color: black;
    margin: 0.3rem;
    border-radius: 10px;
    font-size: 2em;
    line-height: 1.6em;
    transition: 100ms;
}

.tile.free {
    background-color: white;
}

.tile.taken {
    background-color: wheat;
}

.tile.possible {
    background-color: #bbb;
}

.tile.done {
    background-color: black;
    color: wheat;
}
.row {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.button {
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background-color: wheat;
    color: #242424;
    border-radius: 50%;
    font-size: 1.7em;
    cursor: pointer;
    transition: 200ms;
}

.button i {
    margin: 0 auto;
}

.button:hover {
    background-color: rgb(187, 170, 139);
}

.button:active {
    background-color: rgb(141, 127, 103);
}
#grid {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 0.3em;
    border-radius: 20px;
}

.buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 3em auto;
}

#bottom-txt {
    color: white;
    text-align: center;
    font-size: 3em;
    margin-top: 1.5em;
}
