.title {
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 1em;
}

.title h1 {
    font-size: 5em;
    line-height: 0em;
    color: wheat;
}